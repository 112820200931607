export function disconnect () {
  localStorage.removeItem('token')
  localStorage.removeItem('role')
  localStorage.removeItem('id')
  localStorage.removeItem('profile')
  sessionStorage.removeItem('id')
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('role')
  sessionStorage.removeItem('profile')
  window.location.href = '/'
}
